.footer {
  color: #3490ca;
  font-family: Arial, sans-serif;
  font-size: 12px;
  margin-top: 1rem;
  padding: 0 0.5rem;
  text-decoration: none;
}

.link {
  color: inherit;
  text-decoration: none;
  margin-right: 0.75rem;
}

.link:hover {
  text-decoration: underline;
}

.copyright {
  color: rgb(115, 115, 115);
  display: block;
  font-size: 11px;
  margin: 1rem 0;
}

.divider {
  background-color: #dddfe2;
  height: 1px;
  margin: 0.25rem 0;
  width: 100%;
}
