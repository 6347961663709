html,
body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

#root > main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  margin: 0 auto;
  max-width: 798px;
  width: 100%;
}
